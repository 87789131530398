@import (reference) "../../../node_modules/bootstrap/less/bootstrap.less";
@import (reference) "_variables.less";
@import "../../../node_modules/bootstrap/less/normalize.less";
@import "../../../node_modules/bootstrap/less/media.less";

*,
:after,
:before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

@keyframes left-navigation-placeholder-pulse {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}

#navigation-skeleton {
  &.initial-skeleton {
    position: relative;
    display: flex;
    z-index: 11;
    width: 250px;
    height: 100%;

    .skeleton-el {
      background: linear-gradient(90deg, #f1f3f8 25%, #d8dfea 37%, #f1f3f8 63%);
      background-size: 400% 100%;
      animation: left-navigation-placeholder-pulse 1.4s ease infinite;
    }

    #main-nav-skeleton {
      .flex-col;
      justify-content: space-between;
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      padding-top: 11px;
      padding-bottom: 19px;
      background: #fff;
      width: 44px;

      .skeleton-sect {
        .flex-col;
        align-items: center;

        .skeleton-el {
          height: 20px;
          width: 20px;
          margin-bottom: 20px;
          border-radius: 50%;

          &.big {
            height: 25px;
            width: 25px;

            &.last {
              margin-top: 18px;
            }
          }

          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }

    #sub-nav-skeleton {
      position: fixed;
      top: 0;
      padding-top: 11px;
      left: 44px;
      padding-left: 13px;
      padding-right: 10px;
      bottom: 0;
      width: 206px;
      background: #fff;
      .skeleton-sect {
        margin-bottom: 35px;

        &:first-of-type {
          margin-bottom: 31px;
          margin-top: 4px;
        }

        .skeleton-el {
          height: 12px;
          border-radius: 3px;

          margin-bottom: 17px;

          &.big {
            height: 18px;
          }
        }
      }
    }
  }
}
